/* latin-ext */
@font-face {
  font-family: '__DM_Sans_1c85d4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_1c85d4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_1c85d4';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_1c85d4';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_1c85d4';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_1c85d4';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_1c85d4';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_1c85d4';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_1c85d4';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_1c85d4';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Sans_Fallback_1c85d4';src: local("Arial");ascent-override: 94.37%;descent-override: 29.49%;line-gap-override: 0.00%;size-adjust: 105.12%
}.__className_1c85d4 {font-family: '__DM_Sans_1c85d4', '__DM_Sans_Fallback_1c85d4';font-style: normal
}

/* This code is subject to LICENSE in root of this repository */

/* Used to detect in JavaScript if apps have loaded styles or not. */
:root {
	--reach-dialog: 1;
}

[data-reach-dialog-overlay] {
	background: hsla(0, 0%, 0%, 0.33);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
}

[data-reach-dialog-content] {
	width: 50vw;
	margin: 10vh auto;
	background: white;
	padding: 2rem;
	outline: none;
}

@charset "UTF-8";
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  margin-left: -4px;
  position: absolute;
  width: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  top: 0;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  bottom: 0;
  margin-bottom: -8px;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  border-bottom: none;
  border-top-color: #fff;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  bottom: 0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}
.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}
.react-datepicker-popper[data-placement^=bottom] {
  padding-top: 10px;
}
.react-datepicker-popper[data-placement=bottom-end] .react-datepicker__triangle, .react-datepicker-popper[data-placement=top-end] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}
.react-datepicker-popper[data-placement^=top] {
  padding-bottom: 10px;
}
.react-datepicker-popper[data-placement^=right] {
  padding-left: 8px;
}
.react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}
.react-datepicker-popper[data-placement^=left] {
  padding-right: 8px;
}
.react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}
.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 15px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}
.react-datepicker__navigation--previous {
  left: 2px;
}
.react-datepicker__navigation--next {
  right: 2px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
}
.react-datepicker__navigation:hover *::before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}
.react-datepicker__navigation-icon--next {
  left: -2px;
}
.react-datepicker__navigation-icon--next::before {
  transform: rotate(45deg);
  left: -7px;
}
.react-datepicker__navigation-icon--previous {
  right: -2px;
}
.react-datepicker__navigation-icon--previous::before {
  transform: rotate(225deg);
  right: -7px;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
  -moz-appearance: textfield;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}
.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -87px;
  top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}
.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}
.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #bad9f1;
  color: rgb(0, 0, 0);
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range), .react-datepicker__year--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0.5rem;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 25px;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  transform: rotate(135deg);
  right: -16px;
  top: 0;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "×";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__children-container {
  width: 13.8rem;
  margin: 0.4rem;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  height: auto;
}

.react-datepicker__aria-live {
  position: absolute;
  -webkit-clip-path: circle(0);
          clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}

.react-datepicker__calendar-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
}

input[type='range'] {
    padding: 19px 10px;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 30px;
    width: 30px;
    padding: 10px;
    background: #ffffff;
    border: 1px solid;
}

input[type='range']::-moz-range-thumb {
    -webkit-appearance: none;
    height: 30px;
    width: 30px;
    background: #0275f9;
    border: 1px solid;
    border-radius: 50%;
    margin-top: 0;
}

input[type='range']::-moz-range-track {
    -webkit-appearance: none;
    background: #0275f9;
    height: 10px;
    border: 1px solid;
    border-radius: 5px;
    margin-top: 0;
    outline: none;
}

input[type='range']::-ms-thumb {
    -webkit-appearance: none;
    width: 30px;
    height: 30px;
    background: #ffffff;
    border: 1px solid;
    margin-top: 0;
    outline: none;
}

.dmSansNormal {
    font-family: 'DM Sans', sans-serif;
    font-weight: 400 !important;
}

.css-9oh0uv-menu {
    position: inherit !important;
}

.font-zilla {
    font-family: 'Zilla Slab' !important;
}

.giphy-search-bar {
    height: 50px;
}

.slick-track {
    min-width: 700px;
}

.gradientHeading {
    font-size: 42px !important;
    font-family: 'Zilla Slab' !important;
    background: transparent linear-gradient(110deg, #ed2365 0%, #ed2365 0%, #c8368c 35%, #a846af 65%, #6269f9 100%) 0% 0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    font-weight: 500 !important;
}

::-webkit-scrollbar {
    width: 5px;
    height: 1px;
}

::-webkit-scrollbar-track {
    background-color: transparent !important;
    border-radius: 50px;
}

/* For Firefox */
/* Note: Firefox currently only supports changing the thumb color */
/* You can't change the track or other properties */
/* This may change in future Firefox versions */
/* Adjust the color as per your preference */
/* You can also add more vendor prefixes if needed */
/* Refer to MDN for more details: https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-color */
/* scrollbar-color: #888 #f1f1f1; */

::-webkit-scrollbar-thumb {
    background-color: transparent !important;
    border-radius: 50px;
}

.move-in-from-right {
    animation-name: moveInRight;
    animation-duration: 2s;
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

.contributorListContainer {
    padding: 10px;
    width: 100vw;
    height: 40px;
    left: 0;
    right: 0;
    bottom: 10px;
    background: rgba(245, 245, 245, 0.9);
    overflow: hidden;
}

.scroll-element {
    width: max-content;
    height: inherit;
    white-space: nowrap;
}

.scroll-text {
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #000000;
}

@keyframes moveSlideshow {
    from {
        transform: translateX(100vw);
    }

    to {
        transform: translateX(-100%);
    }
}

@font-face {
    font-family: 'Bickham Script One';
    src: local('Bickham-Script-One'), url(/fonts/Bickham-Script-One.ttf);
}

@font-face {
    font-family: 'Old English Text MT';
    src: local('Old English Text MT'),
        url(/fonts/Old-English-Text-Mt-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Aclonica Regular';
    src: local('Aclonica Regular'), url(/fonts/Aclonica-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'AdventPro Bold';
    src: local('AdventPro Bold'), url(/fonts/AdventPro-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'AdventPro ExtraLight';
    src: local('AdventPro ExtraLight'),
        url(/fonts/AdventPro-ExtraLight.ttf) format('truetype');
}

@font-face {
    font-family: 'AdventPro Light';
    src: local('AdventPro Light'), url(/fonts/AdventPro-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'AdventPro Medium';
    src: local('AdventPro Medium'), url(/fonts/AdventPro-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'AdventPro Regular';
    src: local('AdventPro Regular'), url(/fonts/AdventPro-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'AdventPro SemiBold';
    src: local('AdventPro SemiBold'), url(/fonts/AdventPro-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'AdventPro Thin';
    src: local('AdventPro Thin'), url(/fonts/AdventPro-Thin.ttf) format('truetype');
}

@font-face {
    font-family: 'Arizonia Regular';
    font-style: normal;
    font-weight: 400;
    src: local('Arizonia-Regular'), url(/fonts/Arizonia-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Barbershop in Thailand';
    src: local('Barbershop in Thailand'),
        url(/fonts/Barbershop-in-Thailand.ttf) format('truetype');
}

@font-face {
    font-family: 'Bhavuka';
    src: local('Bhavuka'),
        url(/fonts/Bhavuka-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Corinthia Regular';
    src: local('Corinthia Regular'), url(/fonts/Corinthia-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Ephesis Regular';
    src: local('Ephesis Regular'), url(/fonts/Ephesis-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Geometria';
    src: local('Geometria'), url(/fonts/Geometria-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Actor';
    src: local('Actor'), url(/fonts/Actor-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Italianno Regular';
    src: local('Italianno Regular'), url(/fonts/Italianno-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Trajan Pro';
    src: local('Trajan Pro'), url(/fonts/TrajanPro.ttf) format('truetype');
}

@font-face {
    font-family: 'Trajan Pro Bold';
    src: local('Trajan Pro Bold'), url(/fonts/TrajanPro-Bold.ttf) format('opentype');
}

@font-face {
    font-family: 'Trajan Pro Regular';
    src: local('Trajan Pro Regular'), url(/fonts/TrajanPro-Regular.ttf) format('opentype');
}

@font-face {
    font-family: 'Abril Fatface';
    src: local('Abril Fatface Regular'),
        url(/fonts/AbrilFatface-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Bree Serif';
    src: local('Bree Serif Regular'), url(/fonts/BreeSerif-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Cantata One';
    src: local('Cantata One Regular'), url(/fonts/CantataOne-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Secular One';
    src: local('Secular One Regular'), url(/fonts/SecularOne-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Vidaloka';
    src: local('Vidaloka Regular'), url(/fonts/Vidaloka-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Permanent Marker';
    src: local('Permanent Marker Regular'), local('PermanentMarker-Regular'),
        url(https://fonts.gstatic.com/s/permanentmarker/v9/Fh4uPib9Iyv2ucM6pGQMWimMp004La2Cf5b6jlg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Lobster';
    font-style: normal;
    font-weight: 400;
    src: local('Lobster Regular'), local('Lobster-Regular'),
        url(https://fonts.gstatic.com/s/lobster/v22/neILzCirqoswsqX9zoKmM4MwWJU.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

@font-face {
    font-family: 'Rock Salt';
    font-style: normal;
    font-weight: 400;
    src: local('Rock Salt Regular'), local('/fonts/RockSalt-Regular');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Dancing Script';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/dancingscript/v15/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Sup6hNX6plRP.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Shadows Into Light';
    font-style: normal;
    font-weight: 400;
    src: local('Shadows Into Light'), local('ShadowsIntoLight'),
        url(https://fonts.gstatic.com/s/shadowsintolight/v9/UqyNK9UOIntux_czAvDQx_ZcHqZXBNQzdcD55TecYQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    src: local('Merriweather Regular'), local('Merriweather-Regular'),
        url(https://fonts.gstatic.com/s/merriweather/v21/u-440qyriQwlOrhSvowK_l5-fCZMdeX3rg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Rowdies';
    font-style: normal;
    font-weight: 400;
    src: local('Rowdies Regular'), local('Rowdies-Regular'),
        url(https://fonts.gstatic.com/s/rowdies/v1/ptRJTieMYPNBAK21_rBDwcTaJQs.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
    box-sizing: border-box;
    font-size: 16px;
}

body {
    background: #ffffff;
    color: #222333;
}

div,
button {
    letter-spacing: -0.022em;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

ol,
ul {
    list-style: none;
}

img {
    max-width: 100%;
    height: auto;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%;
    /* Player ratio: 100 / (1280 / 720) */
}

#final-video-player {
    height: 100%;
    width: 100%;
    max-width: 922px;
    transition: all 0.5s linear;
}

.video-wrapper {
    height: 100% !important;
    width: 100% !important;
}

.trim-video-wrapper {
    width: 100% !important;
}

.player-wrapper {
    height: 100%;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.react-player>video {
    border-radius: 6px 6px 0px 0px;
}

.css-bktav0 {
    z-index: 1;
}

_::-webkit-full-page-media,
_:future,
:root .ios-safari-offset {
    padding-bottom: 65px;
}

.error::placeholder {
    color: red;
    opacity: 1;
}

.posterContainer>.transform-component-module_wrapper__SPB86 {
    margin: auto !important;
}

@media (hover: none) and (pointer: coarse) {
    .posterContainer>.transform-component-module_wrapper__SPB86 {
        width: 100vw !important;
        height: 100% !important;
    }

    .posterContainer>.transform-component-module_wrapper__SPB86>.transform-component-module_content__FBWxo {
        width: 100vw !important;
        overflow: hidden
    }
}

.helper {
    --reactour-accent: #5cb7b7;
    line-height: 1.3;
    color: #2d2323;
}

.mask {
    color: rgba(0, 0, 0, 0.1);
}

button.switch,
button.switch:hover,
button.switch:focus {
    outline: none;
    border: 0px solid grey;
    box-shadow: none;
    width: 70px;
    height: 34px;
}

button.switch>div {
    content: '';
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: 0px;
    border-color: transparent;
}

button.switch>div:after {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    left: 4px;
    top: 0px;
    transition: left 0.3s ease, background-color 0.3s ease, box-shadow 0.1s ease,
        transform 0.1s ease;
    background-color: #fff;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

button.normalize[aria-checked='true'] {
    background: linear-gradient(-37deg,
            rgb(237, 35, 101) 0%,
            rgb(98, 105, 249) 100%) !important;
}

button.normalize[aria-checked='true']>div:after {
    background-color: #fff !important;
    transform: translateX(24px) !important;
}

button.normalize[aria-checked='false'] {
    background-color: #000 !important;
    left: 18px;
}

button.normalize[disabled] {
    cursor: not-allowed !important;
}

button.switch[aria-checked='true'] {
    background-color: #ee2466;
}

button.switch[aria-checked='true']>div:after {
    background-color: #fff;
    transform: translateX(24px) !important;
}

button.switch[aria-checked='false'] {
    background-color: #ee2466;
    left: 18px;
}

.barlow-condensed {
    font-family: 'Barlow Condensed', sans-serif;
}

.learnMore {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #3b3f85;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    margin-left: 5px;
}

.font-alta {
    font-family: 'Alata' !important;
}

.css-1dlewv6::after {
    background-color: inherit;
    border: 3px solid transparent;
    border-radius: 8px;
    content: '';
    display: block;
    height: calc(100% + 6px);
    left: 0;
    position: absolute;
    top: -3px;
    transform: skewX(-13deg);
    transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
    width: 100%;
    z-index: -1;
}

.css-16qlixr {
    border-style: unset !important;
}

.css-1oi4414 {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.clip-loader {
    border: 1px solid #6369ef;
    /* Light grey */
    border-top: 1px solid #ffffff;
    /* Blue */
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 2s linear infinite;
}

.pulse {
    animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
    }

    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.uppy-StatusBar-actions {
    opacity: 0 !important;
}

.uppy-StatusBar-progress {
    opacity: 0 !important;
}

.uppy-StatusBar::before {
    opacity: 0 !important;
}

.template-tag-item {
    background-color: rgba(98, 109, 240, 0.1);
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    line-height: 14px;
    border-radius: 30px;
    height: 30px;
    padding: 0 8px 0 8px;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
    max-width: 100%;
}

.template-tag-item>.span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #626df0;
    font-family: 'DM Sans';
}

.tag-item {
    background-color: #7B33FB;
    display: inline-block;
    font-size: 16px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
    max-width: 100%;
}

.tag-item>.button {
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pointerCursor {
    cursor: pointer;
}

.tag-item>.span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
}

.css-1yqw443 {
    border: solid 1px #cbd5e0 !important;
    border-right: 1px !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border-right-width: 0px !important;
    font-size: 16px !important;
    outline: 0 !important;
    height: 42px !important;
}

.css-maquk2 {
    height: 50px !important;
}

.giphy-grid {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 300px);
    max-width: 100% !important;
    padding-bottom: 20px;
}

.link {
    font-weight: bold;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    cursor: pointer;
    color: black;
}

.disabledVideoContentContainer {
    margin: auto;
    margin-bottom: 41px;
}

.disabledVideoContentParagraph {
    fontfamily: Work Sans;
    fontstyle: normal;
    fontheight: normal;
    fontsize: 16px;
    lineheight: 150%;
    letterspacing: 0.01em;
    color: #000000;
}

.numberItems {
    width: 30px;
}

.textLoading:after {
    content: ' .';
    animation: textdots 1s steps(5, end) infinite;
}

.rotate-control {
    position: absolute;
    bottom: 0px;
    margin-bottom: -20px;
    width: 10px;
    height: 10px;
    background-color: green;
    margin-left: -5px;
    background-color: black;
}

.rotate-control {
    left: 50%;
}

.resize-control {
    bottom: 0px;
    right: 0px;
}

.rotate-control:hover {
    cursor: pointer;
}

.rotate-control:before {
    content: attr(data-attr)'\00B0';
}


.content {
    position: absolute;
}

.resizeControls {
    width: auto;
    height: auto;
}

.posterEditiorNavText {
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.22px;
}

.editContainer {
    position: absolute;
    background-color: lightgrey;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    cursor: standard;
    /* uncomment below to see rotation */
    /*transform: rotate(45deg);*/
}


.resizeHandle {
    width: 10px;
    height: 10px;
    position: absolute;
    background-color: darkgreen;
    opacity: 1;
    margin: -8px;
}

.corner {
    background-color: transparent;
    border: 3px solid darkgreen;
}

.resizeHandle.topLeft {
    top: 0px;
    left: 0px;
    cursor: nw-resize;
    border-radius: 0;
    border-right: none;
    border-bottom: none;
}

.resizeHandle.top {
    top: 0px;
    left: 50%;
    margin-left: -5px;
    cursor: n-resize;
}

.resizeHandle.topRight {
    top: 0px;
    right: 0px;
    cursor: ne-resize;
    border-left: none;
    border-bottom: none;
}

.resizeHandle.right {
    top: 50%;
    right: 0px;
    margin-top: -5px;
    cursor: e-resize;
}

.resizeHandle.bottomRight {
    bottom: 0px;
    right: 0px;
    cursor: se-resize;
    border-left: none;
    border-top: none;
}

.decimal {
    position: relative;
    top: -1em;
    font-size: 16px;
}

.resizeHandle.bottom {
    bottom: 0px;
    right: 50%;
    margin-right: -5px;
    cursor: s-resize;
}

.resizeHandle.bottomLeft {
    bottom: 0px;
    left: 0px;
    cursor: sw-resize;
    border-right: none;
    border-top: none;
}

.resizeHandle.left {
    top: 50%;
    left: 0px;
    margin-top: -5px;
    cursor: w-resize;
}

@keyframes textdots {

    0%,
    20% {
        color: rgba(0, 0, 0, 0);
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
        color: #ed2365;
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
        text-shadow: 0.25em 0 0 #ed2365, 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
        text-shadow: 0.25em 0 0 #ed2365, 0.5em 0 0 #ed2365;
    }
}

.loading:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;
}

.css-rbhblc {
    border-radius: 4px !important;
}

@keyframes dots {

    0%,
    20% {
        color: rgba(0, 0, 0, 0);
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
        color: white;
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
        text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
        text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
    }
}

@media screen and (max-width: 1224px) {
    .css-1ktp073 {
        width: auto !important;
    }
}

@media screen and (max-width: 1033px) {
    .player-wrapper {
        height: unset;
    }

    .css-1ktp073 {
        width: auto !important;
    }

    .css-1d12lom,
    .css-1cwdj3y {
        /* //margin for sidebar in dashboard */
        margin-left: 220px !important;
    }
}

@media screen and (max-width: 1024px) {
    .react-datepicker-wrapper {
        width: 100%;
    }
}

@media screen and (max-width: 880px) {
    .css-3mjire {
        grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
        overflow-x: auto;
    }

    .css-1t9tw3p {
        grid-template-columns: 1fr !important;
        grid-column-gap: 20px;
    }
}

@media screen and (max-width: 616px) {
    .css-19yth21 {
        grid-template-columns: repeat(auto-fill, minmax(47vw, 47vw)) !important;
    }
}

@media screen and (max-width: 580px) {
    .css-j7p71z {
        overflow-x: auto;
    }
}

@media screen and (max-width: 560px) {

    .css-a3wum,
    .css-13drdk5 {
        grid-template-columns: repeat(auto-fill, minmax(46vw, 46vw)) !important;
    }
}

@media screen and (max-width: 532px) {
    .css-19yth21 {
        grid-template-columns: repeat(auto-fill, minmax(47vw, 46vw)) !important;
    }
}

@media screen and (max-width: 500px) {
    .css-1sd4bqu {
        grid-template-columns: 1fr !important;
        grid-column-gap: 20px;
    }

    .css-xoq78v {
        width: 100vw;
        justify-content: center;
    }

    .css-zoekl7 {
        width: 70vw !important;
    }
}

@media screen and (max-width: 400px) {
    .css-19yth21 {
        grid-template-columns: repeat(auto-fill, minmax(46.5vw, 45vw)) !important;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (-webkit-user-agent-string: "iPhone") {

    video::-webkit-media-controls-start-playback-button,
    video::-webkit-media-controls-enclosure {
        display: none !important;
    }
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_543fe8';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_543fe8';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_543fe8';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_543fe8';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_543fe8';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_543fe8';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_543fe8';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_543fe8';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_543fe8';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_543fe8';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_543fe8';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_543fe8';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Sans_Fallback_543fe8';src: local("Arial");ascent-override: 94.37%;descent-override: 29.49%;line-gap-override: 0.00%;size-adjust: 105.12%
}.__className_543fe8 {font-family: '__DM_Sans_543fe8', '__DM_Sans_Fallback_543fe8';font-style: normal
}

