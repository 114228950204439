input[type='range'] {
    padding: 19px 10px;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 30px;
    width: 30px;
    padding: 10px;
    background: #ffffff;
    border: 1px solid;
}

input[type='range']::-moz-range-thumb {
    -webkit-appearance: none;
    height: 30px;
    width: 30px;
    background: #0275f9;
    border: 1px solid;
    border-radius: 50%;
    margin-top: 0;
}

input[type='range']::-moz-range-track {
    -webkit-appearance: none;
    background: #0275f9;
    height: 10px;
    border: 1px solid;
    border-radius: 5px;
    margin-top: 0;
    outline: none;
}

input[type='range']::-ms-thumb {
    -webkit-appearance: none;
    width: 30px;
    height: 30px;
    background: #ffffff;
    border: 1px solid;
    margin-top: 0;
    outline: none;
}

.dmSansNormal {
    font-family: 'DM Sans', sans-serif;
    font-weight: 400 !important;
}

.css-9oh0uv-menu {
    position: inherit !important;
}

.font-zilla {
    font-family: 'Zilla Slab' !important;
}

.giphy-search-bar {
    height: 50px;
}

.slick-track {
    min-width: 700px;
}

.gradientHeading {
    font-size: 42px !important;
    font-family: 'Zilla Slab' !important;
    background: transparent linear-gradient(110deg, #ed2365 0%, #ed2365 0%, #c8368c 35%, #a846af 65%, #6269f9 100%) 0% 0% no-repeat padding-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    font-weight: 500 !important;
}

::-webkit-scrollbar {
    width: 5px;
    height: 1px;
}

::-webkit-scrollbar-track {
    background-color: transparent !important;
    border-radius: 50px;
}

/* For Firefox */
/* Note: Firefox currently only supports changing the thumb color */
/* You can't change the track or other properties */
/* This may change in future Firefox versions */
/* Adjust the color as per your preference */
/* You can also add more vendor prefixes if needed */
/* Refer to MDN for more details: https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-color */
/* scrollbar-color: #888 #f1f1f1; */

::-webkit-scrollbar-thumb {
    background-color: transparent !important;
    border-radius: 50px;
}

.move-in-from-right {
    animation-name: moveInRight;
    animation-duration: 2s;
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

.contributorListContainer {
    padding: 10px;
    width: 100vw;
    height: 40px;
    left: 0;
    right: 0;
    bottom: 10px;
    background: rgba(245, 245, 245, 0.9);
    overflow: hidden;
}

.scroll-element {
    width: max-content;
    height: inherit;
    white-space: nowrap;
}

.scroll-text {
    font-family: 'DM Sans';
    font-style: italic;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #000000;
}

@keyframes moveSlideshow {
    from {
        transform: translateX(100vw);
    }

    to {
        transform: translateX(-100%);
    }
}

@font-face {
    font-family: 'Bickham Script One';
    src: local('Bickham-Script-One'), url(/fonts/Bickham-Script-One.ttf);
}

@font-face {
    font-family: 'Old English Text MT';
    src: local('Old English Text MT'),
        url(/fonts/Old-English-Text-Mt-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Aclonica Regular';
    src: local('Aclonica Regular'), url(/fonts/Aclonica-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'AdventPro Bold';
    src: local('AdventPro Bold'), url(/fonts/AdventPro-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'AdventPro ExtraLight';
    src: local('AdventPro ExtraLight'),
        url(/fonts/AdventPro-ExtraLight.ttf) format('truetype');
}

@font-face {
    font-family: 'AdventPro Light';
    src: local('AdventPro Light'), url(/fonts/AdventPro-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'AdventPro Medium';
    src: local('AdventPro Medium'), url(/fonts/AdventPro-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'AdventPro Regular';
    src: local('AdventPro Regular'), url(/fonts/AdventPro-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'AdventPro SemiBold';
    src: local('AdventPro SemiBold'), url(/fonts/AdventPro-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'AdventPro Thin';
    src: local('AdventPro Thin'), url(/fonts/AdventPro-Thin.ttf) format('truetype');
}

@font-face {
    font-family: 'Arizonia Regular';
    font-style: normal;
    font-weight: 400;
    src: local('Arizonia-Regular'), url(/fonts/Arizonia-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Barbershop in Thailand';
    src: local('Barbershop in Thailand'),
        url(/fonts/Barbershop-in-Thailand.ttf) format('truetype');
}

@font-face {
    font-family: 'Bhavuka';
    src: local('Bhavuka'),
        url(/fonts/Bhavuka-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Corinthia Regular';
    src: local('Corinthia Regular'), url(/fonts/Corinthia-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Ephesis Regular';
    src: local('Ephesis Regular'), url(/fonts/Ephesis-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Geometria';
    src: local('Geometria'), url(/fonts/Geometria-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Actor';
    src: local('Actor'), url(/fonts/Actor-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Italianno Regular';
    src: local('Italianno Regular'), url(/fonts/Italianno-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Trajan Pro';
    src: local('Trajan Pro'), url(/fonts/TrajanPro.ttf) format('truetype');
}

@font-face {
    font-family: 'Trajan Pro Bold';
    src: local('Trajan Pro Bold'), url(/fonts/TrajanPro-Bold.ttf) format('opentype');
}

@font-face {
    font-family: 'Trajan Pro Regular';
    src: local('Trajan Pro Regular'), url(/fonts/TrajanPro-Regular.ttf) format('opentype');
}

@font-face {
    font-family: 'Abril Fatface';
    src: local('Abril Fatface Regular'),
        url(/fonts/AbrilFatface-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Bree Serif';
    src: local('Bree Serif Regular'), url(/fonts/BreeSerif-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Cantata One';
    src: local('Cantata One Regular'), url(/fonts/CantataOne-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Secular One';
    src: local('Secular One Regular'), url(/fonts/SecularOne-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Vidaloka';
    src: local('Vidaloka Regular'), url(/fonts/Vidaloka-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Permanent Marker';
    src: local('Permanent Marker Regular'), local('PermanentMarker-Regular'),
        url(https://fonts.gstatic.com/s/permanentmarker/v9/Fh4uPib9Iyv2ucM6pGQMWimMp004La2Cf5b6jlg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Lobster';
    font-style: normal;
    font-weight: 400;
    src: local('Lobster Regular'), local('Lobster-Regular'),
        url(https://fonts.gstatic.com/s/lobster/v22/neILzCirqoswsqX9zoKmM4MwWJU.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    font-display: swap;
}

@font-face {
    font-family: 'Rock Salt';
    font-style: normal;
    font-weight: 400;
    src: local('Rock Salt Regular'), local('/fonts/RockSalt-Regular');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Dancing Script';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/dancingscript/v15/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Sup6hNX6plRP.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Shadows Into Light';
    font-style: normal;
    font-weight: 400;
    src: local('Shadows Into Light'), local('ShadowsIntoLight'),
        url(https://fonts.gstatic.com/s/shadowsintolight/v9/UqyNK9UOIntux_czAvDQx_ZcHqZXBNQzdcD55TecYQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    src: local('Merriweather Regular'), local('Merriweather-Regular'),
        url(https://fonts.gstatic.com/s/merriweather/v21/u-440qyriQwlOrhSvowK_l5-fCZMdeX3rg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0bf8pkAg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url(https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'Rowdies';
    font-style: normal;
    font-weight: 400;
    src: local('Rowdies Regular'), local('Rowdies-Regular'),
        url(https://fonts.gstatic.com/s/rowdies/v1/ptRJTieMYPNBAK21_rBDwcTaJQs.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
        U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
    box-sizing: border-box;
    font-size: 16px;
}

body {
    background: #ffffff;
    color: #222333;
}

div,
button {
    letter-spacing: -0.022em;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

ol,
ul {
    list-style: none;
}

img {
    max-width: 100%;
    height: auto;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%;
    /* Player ratio: 100 / (1280 / 720) */
}

#final-video-player {
    height: 100%;
    width: 100%;
    max-width: 922px;
    transition: all 0.5s linear;
}

.video-wrapper {
    height: 100% !important;
    width: 100% !important;
}

.trim-video-wrapper {
    width: 100% !important;
}

.player-wrapper {
    height: 100%;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.react-player>video {
    border-radius: 6px 6px 0px 0px;
}

.css-bktav0 {
    z-index: 1;
}

_::-webkit-full-page-media,
_:future,
:root .ios-safari-offset {
    padding-bottom: 65px;
}

.error::placeholder {
    color: red;
    opacity: 1;
}

.posterContainer>.transform-component-module_wrapper__SPB86 {
    margin: auto !important;
}

@media (hover: none) and (pointer: coarse) {
    .posterContainer>.transform-component-module_wrapper__SPB86 {
        width: 100vw !important;
        height: 100% !important;
    }

    .posterContainer>.transform-component-module_wrapper__SPB86>.transform-component-module_content__FBWxo {
        width: 100vw !important;
        overflow: hidden
    }
}

.helper {
    --reactour-accent: #5cb7b7;
    line-height: 1.3;
    color: #2d2323;
}

.mask {
    color: rgba(0, 0, 0, 0.1);
}

button.switch,
button.switch:hover,
button.switch:focus {
    outline: none;
    border: 0px solid grey;
    box-shadow: none;
    width: 70px;
    height: 34px;
}

button.switch>div {
    content: '';
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: 0px;
    border-color: transparent;
}

button.switch>div:after {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    left: 4px;
    top: 0px;
    transition: left 0.3s ease, background-color 0.3s ease, box-shadow 0.1s ease,
        transform 0.1s ease;
    background-color: #fff;
    -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

button.normalize[aria-checked='true'] {
    background: linear-gradient(-37deg,
            rgb(237, 35, 101) 0%,
            rgb(98, 105, 249) 100%) !important;
}

button.normalize[aria-checked='true']>div:after {
    background-color: #fff !important;
    transform: translateX(24px) !important;
}

button.normalize[aria-checked='false'] {
    background-color: #000 !important;
    left: 18px;
}

button.normalize[disabled] {
    cursor: not-allowed !important;
}

button.switch[aria-checked='true'] {
    background-color: #ee2466;
}

button.switch[aria-checked='true']>div:after {
    background-color: #fff;
    transform: translateX(24px) !important;
}

button.switch[aria-checked='false'] {
    background-color: #ee2466;
    left: 18px;
}

.barlow-condensed {
    font-family: 'Barlow Condensed', sans-serif;
}

.learnMore {
    text-decoration-line: underline;
    color: #3b3f85;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    margin-left: 5px;
}

.font-alta {
    font-family: 'Alata' !important;
}

.css-1dlewv6::after {
    background-color: inherit;
    border: 3px solid transparent;
    border-radius: 8px;
    content: '';
    display: block;
    height: calc(100% + 6px);
    left: 0;
    position: absolute;
    top: -3px;
    transform: skewX(-13deg);
    transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
    width: 100%;
    z-index: -1;
}

.css-16qlixr {
    border-style: unset !important;
}

.css-1oi4414 {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.clip-loader {
    border: 1px solid #6369ef;
    /* Light grey */
    border-top: 1px solid #ffffff;
    /* Blue */
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 2s linear infinite;
}

.pulse {
    animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
    }

    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.uppy-StatusBar-actions {
    opacity: 0 !important;
}

.uppy-StatusBar-progress {
    opacity: 0 !important;
}

.uppy-StatusBar::before {
    opacity: 0 !important;
}

.template-tag-item {
    background-color: rgba(98, 109, 240, 0.1);
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    line-height: 14px;
    border-radius: 30px;
    height: 30px;
    padding: 0 8px 0 8px;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
    max-width: 100%;
}

.template-tag-item>.span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #626df0;
    font-family: 'DM Sans';
}

.tag-item {
    background-color: #7B33FB;
    display: inline-block;
    font-size: 16px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
    max-width: 100%;
}

.tag-item>.button {
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pointerCursor {
    cursor: pointer;
}

.tag-item>.span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
}

.css-1yqw443 {
    border: solid 1px #cbd5e0 !important;
    border-right: 1px !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border-right-width: 0px !important;
    font-size: 16px !important;
    outline: 0 !important;
    height: 42px !important;
}

.css-maquk2 {
    height: 50px !important;
}

.giphy-grid {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 300px);
    max-width: 100% !important;
    padding-bottom: 20px;
}

.link {
    font-weight: bold;
    text-decoration-line: underline;
    cursor: pointer;
    color: black;
}

.disabledVideoContentContainer {
    margin: auto;
    margin-bottom: 41px;
}

.disabledVideoContentParagraph {
    fontfamily: Work Sans;
    fontstyle: normal;
    fontheight: normal;
    fontsize: 16px;
    lineheight: 150%;
    letterspacing: 0.01em;
    color: #000000;
}

.numberItems {
    width: 30px;
}

.textLoading:after {
    content: ' .';
    animation: textdots 1s steps(5, end) infinite;
}

.rotate-control {
    position: absolute;
    bottom: 0px;
    margin-bottom: -20px;
    width: 10px;
    height: 10px;
    background-color: green;
    margin-left: -5px;
    background-color: black;
}

.rotate-control {
    left: 50%;
}

.resize-control {
    bottom: 0px;
    right: 0px;
}

.rotate-control:hover {
    cursor: pointer;
}

.rotate-control:before {
    content: attr(data-attr)'\00B0';
}


.content {
    position: absolute;
}

.resizeControls {
    width: auto;
    height: auto;
}

.posterEditiorNavText {
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.22px;
}

.editContainer {
    position: absolute;
    background-color: lightgrey;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    cursor: standard;
    /* uncomment below to see rotation */
    /*transform: rotate(45deg);*/
}


.resizeHandle {
    width: 10px;
    height: 10px;
    position: absolute;
    background-color: darkgreen;
    opacity: 1;
    margin: -8px;
}

.corner {
    background-color: transparent;
    border: 3px solid darkgreen;
}

.resizeHandle.topLeft {
    top: 0px;
    left: 0px;
    cursor: nw-resize;
    border-radius: 0;
    border-right: none;
    border-bottom: none;
}

.resizeHandle.top {
    top: 0px;
    left: 50%;
    margin-left: -5px;
    cursor: n-resize;
}

.resizeHandle.topRight {
    top: 0px;
    right: 0px;
    cursor: ne-resize;
    border-left: none;
    border-bottom: none;
}

.resizeHandle.right {
    top: 50%;
    right: 0px;
    margin-top: -5px;
    cursor: e-resize;
}

.resizeHandle.bottomRight {
    bottom: 0px;
    right: 0px;
    cursor: se-resize;
    border-left: none;
    border-top: none;
}

.decimal {
    position: relative;
    top: -1em;
    font-size: 16px;
}

.resizeHandle.bottom {
    bottom: 0px;
    right: 50%;
    margin-right: -5px;
    cursor: s-resize;
}

.resizeHandle.bottomLeft {
    bottom: 0px;
    left: 0px;
    cursor: sw-resize;
    border-right: none;
    border-top: none;
}

.resizeHandle.left {
    top: 50%;
    left: 0px;
    margin-top: -5px;
    cursor: w-resize;
}

@keyframes textdots {

    0%,
    20% {
        color: rgba(0, 0, 0, 0);
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
        color: #ed2365;
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
        text-shadow: 0.25em 0 0 #ed2365, 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
        text-shadow: 0.25em 0 0 #ed2365, 0.5em 0 0 #ed2365;
    }
}

.loading:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;
}

.css-rbhblc {
    border-radius: 4px !important;
}

@keyframes dots {

    0%,
    20% {
        color: rgba(0, 0, 0, 0);
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
        color: white;
        text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
        text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
        text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
    }
}

@media screen and (max-width: 1224px) {
    .css-1ktp073 {
        width: auto !important;
    }
}

@media screen and (max-width: 1033px) {
    .player-wrapper {
        height: unset;
    }

    .css-1ktp073 {
        width: auto !important;
    }

    .css-1d12lom,
    .css-1cwdj3y {
        /* //margin for sidebar in dashboard */
        margin-left: 220px !important;
    }
}

@media screen and (max-width: 1024px) {
    .react-datepicker-wrapper {
        width: 100%;
    }
}

@media screen and (max-width: 880px) {
    .css-3mjire {
        grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
        overflow-x: auto;
    }

    .css-1t9tw3p {
        grid-template-columns: 1fr !important;
        grid-column-gap: 20px;
    }
}

@media screen and (max-width: 616px) {
    .css-19yth21 {
        grid-template-columns: repeat(auto-fill, minmax(47vw, 47vw)) !important;
    }
}

@media screen and (max-width: 580px) {
    .css-j7p71z {
        overflow-x: auto;
    }
}

@media screen and (max-width: 560px) {

    .css-a3wum,
    .css-13drdk5 {
        grid-template-columns: repeat(auto-fill, minmax(46vw, 46vw)) !important;
    }
}

@media screen and (max-width: 532px) {
    .css-19yth21 {
        grid-template-columns: repeat(auto-fill, minmax(47vw, 46vw)) !important;
    }
}

@media screen and (max-width: 500px) {
    .css-1sd4bqu {
        grid-template-columns: 1fr !important;
        grid-column-gap: 20px;
    }

    .css-xoq78v {
        width: 100vw;
        justify-content: center;
    }

    .css-zoekl7 {
        width: 70vw !important;
    }
}

@media screen and (max-width: 400px) {
    .css-19yth21 {
        grid-template-columns: repeat(auto-fill, minmax(46.5vw, 45vw)) !important;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (-webkit-user-agent-string: "iPhone") {

    video::-webkit-media-controls-start-playback-button,
    video::-webkit-media-controls-enclosure {
        display: none !important;
    }
}